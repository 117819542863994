import React from "react"
import { graphql } from "gatsby"
import * as R from 'ramda'
import Hidden from '@material-ui/core/Hidden'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'

import Layout from "../components/layout"
import SEO from "../components/seo"

//import loadable from '@loadable/component'
import importedComponent from 'react-imported-component';
const Search = importedComponent(() => import('../components/form/Search'));

const SearchPage = ({data, location}) => {  
  const searchIndex = R.path(['siteSearchIndex', 'index'], data);

  return (
    <Layout noHero>
      <SEO
        sitename={R.path(['brandSettings', 'nimi'], data)}
        seoTitle="Haku"
        url={location.href}
      />
      <Container maxWidth="lg">
        <Grid container spacing={3}>
          <Grid item md={3} implementation="css" smDown component={Hidden} />
          <Grid item md={6}>
            <Search searchIndex={searchIndex} />
          </Grid>
        </Grid>
      </Container>
    </Layout>
  )
}

export default SearchPage

// eslint-disable-next-line no-undef
export const pageQuery = graphql`
  query searchPageQuery($brand: String!) {
    siteSearchIndex {
      index
    }
    brandSettings: contentfulKetju(sivupolku: { eq: $brand }) {
      id
      nimi
      sivupolku
    }
  }
`;